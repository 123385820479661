import React, { useCallback, useState, useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link } from './link'
import VideoModal from './video-modal'
import PlayArrowIcon from '../images/icons/material/play_arrow.svg'


const BoxFull = ({ data: { mainImage, infoTitle, infoDescription, videoLink, textSx, btnText } }) => {

  const [showVideo, setShowVideo] = useState(false);

  const playVideo = useCallback((e) => {
    if (videoLink && videoLink.value) {
      e.preventDefault();
      setShowVideo(true);
    }
  }, [videoLink]);

  const hideVideo = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowVideo(false);
  }, []);

  const hasBox = useMemo(() => (infoTitle && infoTitle.value) 
    || (infoDescription && infoDescription.value), [infoTitle, infoDescription]);
  const hasCursor = useMemo(() => !!(btnText && btnText.content 
    && btnText.content.btnLink && btnText.content.btnLink.value) || !!(videoLink && videoLink.value), 
  [btnText, videoLink]);

  return (
    <Link to={btnText && btnText.content && btnText.content.btnLink && btnText.content.btnLink.value} 
      className={`box-full mx-2 mx-md-auto${hasCursor ? ' box-full--cursor' : ''}`} onClick={playVideo}>
      <Img image={getImage(mainImage.node)} className="box-full__img" 
        alt={mainImage.node.description || ''} />
      <div className="container">
        <div className="row">
          <div className={`col-12${hasBox ? ' col-lg-5' : ''} d-flex justify-content-center py-5 px-6 py-lg-7${textSx ? ' order-lg-2': ''}`}>
            <div className="box-full__video d-flex justify-content-center align-items-center">
              {videoLink && videoLink.value && (
                <div className="box-full__play bg-blue-corporate">
                  <PlayArrowIcon className="material-svg text-white" />
                </div>
              )}
            </div>
          </div>
          {hasBox ? (
            <div className="col-12 col-lg-7 py-3 py-lg-7">
              <div className="box-full__box p-4 p-lg-5 p-xl-6 d-flex flex-column justify-content-center">
                {infoTitle && infoTitle.value && (
                  <div className="box-full__title text-left text-lg-center text-white" 
                    dangerouslySetInnerHTML={{__html: infoTitle.value }}></div>
                )}
                {infoDescription && infoDescription.value && (
                <div className="box-full__text font-weight-bold text-left text-lg-center text-white mt-2 px-lg-4"
                  dangerouslySetInnerHTML={{__html: infoDescription.value}}>
                </div>)}
                {btnText && btnText.value &&
                  <button className="nj-btn nj-btn--light mt-2 align-self-center">
                    {btnText.value}
                  </button>
                }
              </div>
            </div>
          ): null}
        </div>
      </div>
      {videoLink && videoLink.value && <VideoModal show={showVideo} handleClose={hideVideo} video={videoLink.value} />}
    </Link>
  );
}

export default BoxFull;
export const boxFullFragment = graphql`fragment BannervideoFragment on LiferayBannervideo{
  siteId
  articleId
  mainImage {
    node {
      description
      gatsbyImageData(width: 1140)
    }
  }
  textSx
  infoTitle {
    value
  }
  infoDescription {
    value
  }
  videoLink {
    value
  }
  btnText {
    value
    content {
      btnLink {
        value
      }
    }
  }
}`;

