import React from 'react'
import { graphql } from 'gatsby'
import GetAppIcon from '../images/icons/material/get_app.svg'


const DataIntro = ({ data: {textLeft, textRight, titleBoxRight, bottomText }}) => {
  return (
    <div className="data-intro mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-xl-6 offset-xl-1 mb-5 mb-md-0 d-flex align-items-center">
            <div className="data-intro__text-left" dangerouslySetInnerHTML={{__html: textLeft.value}}></div>
          </div>
          {textRight && textRight.value ? (
            <div className="col-12 col-md-5 col-lg-3 offset-lg-2 offset-xl-1 d-inline-flex flex-column justify-content-center">
              <div className="data-intro__text-right" dangerouslySetInnerHTML={{__html: textRight.value}}></div>
              {textRight.content && textRight.content.btnText && textRight.content.btnText.value 
                && textRight.content.btnText.content && textRight.content.btnText.content.btnDocument 
                && textRight.content.btnText.content.btnDocument.node ? (
                <div className="mt-3">
                  <a href={textRight.content.btnText.content.btnDocument.node.publicURL} target="_blank" rel="noopener noreferrer" 
                    className="nj-btn nj-btn--primary nj-btn--lg">
                    {textRight.content.btnText.value}
                    <GetAppIcon className="nj-btn__icon material-svg" />
                  </a>
                </div>
              ): null}
            </div>
          ) : null}
          {titleBoxRight && titleBoxRight.value ? (
            <div className="col-12 col-md-5 col-xl-4 d-flex flex-column justify-content-center">
              <div className="data-intro__boxgradient text-center d-flex flex-column justify-content-center align-items-center px-3 py-5">
                <h3>{titleBoxRight.value}</h3>
                {titleBoxRight.content && titleBoxRight.content.descrizioneBoxDestra && titleBoxRight.content.descrizioneBoxDestra.value ? (
                  <div dangerouslySetInnerHTML={{__html: titleBoxRight.content.descrizioneBoxDestra.value}}></div>
                ): null}
              </div>
            </div>
          ): null}
        </div>
        {bottomText && bottomText.value ? (
          <div className="row mt-5">
            <div className="col-12 col-xl-10 offset-xl-1" dangerouslySetInnerHTML={{__html: bottomText.value}}>
            </div>
          </div>
        ): null}
      </div>
    </div>
  )
}
export default DataIntro;
export const dataIntroFragment = graphql`fragment IntrodatiFragment on LiferayIntrodati{
  siteId
  articleId
  textLeft {
    value
  }
  textRight {
    value
    content {
      btnText {
        value
        content {
          btnDocument {
            node {
              publicURL
            }
          }
        }
      }
    }
  }
  titleBoxRight {
    value
    content {
      descrizioneBoxDestra {
        value
      }
    }
  }
  bottomText {
    value
  }
}`;
