import React from 'react'
import { graphql } from 'gatsby'


const FasciaSpiegazione = ({ data: { mainText }}) => {
  return (
    <div className="section fascia-spiegazione mb-5 pb-5">
      <div className="container">
        {mainText.map((item, i) => (
          <div className="row mb-3" key={i}>
            {item.content.mainImage ? (
              <div className={`col-md-6 px-md-0 mb-5 mb-md-0${item.content && item.content.textLeft ? ' order-md-2': ''}`}>
                <div className="d-flex justify-content-center justify-content-md-start align-items-center h-100">
                  <img src={item.content.mainImage.node.publicURL} alt={item.content.mainImage.node.description || ''} />
                </div>
              </div>
            ): null}
            <div className={`col-md-6${item.content && item.content.textLeft ? ' pl-md-0 pr-md-5': ''} d-flex align-items-center`}>
              <div className={`${(item.content && item.content.textLeft) || i > 0 ? '' : 'pl-md-6'}`} 
                dangerouslySetInnerHTML={{__html: item.value }}></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FasciaSpiegazione;
export const fasciaspiegazioneFragment = graphql`fragment FasciaspiegazioneFragment on LiferayFasciaspiegazione{
  siteId
  articleId
  mainText {
    value
    content {
      mainImage {
        node {
          description
          publicURL
        }
      }
      textLeft
    }
  }
}`;
