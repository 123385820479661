import React from 'react'
import { graphql } from 'gatsby'
import GetAppIcon from '../images/icons/material/get_app.svg'


const Attachments = ({ data: { attachmentTitle, attachment }}) => {
  return (
    <div className="attachments mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {attachmentTitle && attachmentTitle.value ? <h3>{attachmentTitle.value}</h3> : null}
            {attachment && attachment.length ? (
              <ul className="attachments__list mt-4">
                {attachment.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.node.publicURL} className="attachments__link" 
                        target="_blank" rel="noopener noreferrer">
                        {item.content && item.content.attachmentDescription && item.content.attachmentDescription.value}
                        <GetAppIcon className="material-svg" />
                      </a>
                    </li>
                  )
                })}
              </ul>
            ): null}
          </div>
        </div>
      </div>
    </div>
  )

}

export default Attachments;
export const attachmentsFragment = graphql`fragment AllegatiFragment on LiferayAllegati{
  siteId
  articleId
  attachmentTitle {
    value
  }
  attachment {
    content {
      attachmentDescription {
        value
      }
    }
    node {
      publicURL
    }
  }
}`;
