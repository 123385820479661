import React from "react";
import { graphql } from "gatsby";

const StickyFooterCtaLink = ({ data }) => {
  console.log("StickyFooterCtaLink: ", data);
  const { content, ctalabel, ctalink, ctastyle } = data;

  return (
    <div className="container-fluid px-0">
      <div className="sticky-footer">
        <div className="sticky-footer-content">
          <div className="d-none d-md-flex col-xl-5 col-6 p-0 text-nowrap">
            <p className="sticky-footer__intro">{content.value}</p>
          </div>
          <div className="d-none d-xl-flex col-3"></div>
          <div className="d-flex flex-column flex-md-row text-center">
            <div className="d-flex flex-column flex-md-row text-center">
              <button
                title={ctalabel.value}
                onClick={() => {
                  document.location.href = `${ctalink.value}`;
                }}
                className="cta-link sticky-footer__cta w-100 text-center text-nowrap my-1 my-md-0 primary"
              >
                {ctalabel.value}
              </button>
            </div>
            <div className="d-flex flex-column flex-md-row text-center"></div>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: ctastyle?.value }} />
    </div>
  );
};

export default StickyFooterCtaLink;
export const stickyFooterCtaLinkFragment = graphql`
  fragment StickyFooterCtaLinkFragment on LiferayStickyFooterCtaLink {
    siteId
    articleId
    ctalink {
      value
    }
    ctalabel {
      value
    }
    ctastyle {
      value
    }
    content {
      value
    }
  }
`;
