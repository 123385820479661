import React, { useCallback, useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import useBlockScroll from '../utility/useBlockScroll'


const PrevArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`} aria-label="Prev">
  <i className="nj-icon nj-icon-arrowleft nj-icon--white size-60"></i>
</button>;
const NextArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`} aria-label="Next">
  <i className="nj-icon nj-icon-arrowright nj-icon--white size-60"></i>
</button>;

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  swipe: true,
  slidesToShow: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />
};

const Lightbox = ({ show, onHide, elements, startIndex }) => {
  const wrapperRef = useRef(null);
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(null);
  const [slider, setSlider] = useState(null);
  const onTransitionEnd = useCallback(() => {
    if (!loading) {
      onHide();
    }
  }, [loading, onHide]);

  const close = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(false);
  }, []);

  const closeOnEscape = useCallback(e => {
    if (e.key === 'Escape') {
      close(e);
    }
  }, [close]);

  useBlockScroll(show);

  useEffect(() => {
    if (!wrapperRef.current) {
      wrapperRef.current = document.createElement('div');
    }
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  });

  useEffect(() => {
    if (show) {
      setLoading(true);
      document.body.appendChild(wrapperRef.current);
      return () => document.body.removeChild(wrapperRef.current);
    } else {
      setLoading(false);
    }
  }, [show]);

  useEffect(() => {
    if (loading && slider) {
      slider.slickGoTo(startIndex);
    }
  }, [loading, slider, startIndex]);

  return wrapperRef.current && show ? ReactDOM.createPortal(
    (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex
      <div ref={modalRef} onKeyDown={closeOnEscape} tabIndex={0} onTransitionEnd={onTransitionEnd} 
        className={`lightbox ${loading ? 'lightbox--visible': ''} d-flex justify-content-center align-items-center`} >
        <button onClick={close} className="lightbox__close icon-btn" aria-label="Chiudi" />
        <Slider {...settings} ref={setSlider} className="lightbox__slider">
          {elements.map((item, i) => (
            <div className="lightbox__slide d-inline-flex justify-content-center align-items-center" key={i}>
              {item}
            </div>
          ))}
        </Slider>
      </div>
    ),
    wrapperRef.current
  ) : null;
}

export default Lightbox;