import React, { useCallback, useMemo, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import Lightbox from "./lightbox"


const Gallery = ({ data: { galleryTitle, images, loadBtnLabel } }) => {
  const [loaded, setLoaded] = useState(8);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const loadedImages = useMemo(() => images.slice(0, loaded) || [], [images, loaded]);
  const loadMore = useCallback(() => setLoaded(value => value + 4), []);
  const showImage = useCallback((e) => {
    setIsOpen(true);
    setPhotoIndex(Number(e.currentTarget.name));
  }, [setIsOpen, setPhotoIndex]);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <section className="gallery pb-5 mb-5">
      <div className="container">
        {galleryTitle?.value && (
          <div className="row">
            <div className="col-12">
              <h2 className="gallery__title text-center mb-4">
                {galleryTitle.value}
              </h2>
            </div>
          </div>
        )}
        <div className="gallery__content">
          <div className="row">
            {loadedImages.map((img, index) => (
              <div className="col-6 col-md-4 col-lg-3 mb-2" key={img.node.liferayId}>
                <button className="gallery__item" name={index} onClick={showImage}>
                  <Img alt={img.node.description} image={getImage(img.node.thumb)} />
                </button>
              </div>
            ))}
          </div>
        </div>
        {loaded < images.length && (
          <div className="row">
            <div className="col-12 text-center py-4">
              <button className="nj-btn nj-btn--primary" onClick={loadMore}>
                {loadBtnLabel?.value || 'Carica altro'}
              </button>
            </div>
          </div>
        )}
      </div>
      <Lightbox
        show={isOpen}
        onHide={close}
        elements={loadedImages.map((img) => (
          <div key={img.node.liferayId} 
            className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <Img 
              alt={img.node.description} 
              image={getImage(img.node.slider)} 
              className="w-100"
              loading="eager"
              imgStyle={{ objectFit: 'contain' }}
            />
            {img.content?.caption?.value ? (
              <div className="gallery__caption text-center p-2"
                dangerouslySetInnerHTML={{ __html: img.content.caption.value }}>
              </div>
            ): null}
          </div>
        ))}
        startIndex={photoIndex}
      />
    </section>
  );
}

export default Gallery;
export const dataIntroFragment = graphql`fragment GalleryFragment on LiferayGallery {
  siteId
  articleId
  galleryTitle {
    value
  }
  images {
    node {
      liferayId
      description
      publicURL
      thumb: gatsbyImageData(height: 500)
      slider: gatsbyImageData(width: 1440)
    }
    content {
      caption {
        value
      }
    }
  }
  loadBtnLabel {
    value
  }
}`;
