import React, { useRef, useCallback, useEffect } from 'react'
import Slider from "react-slick"
import useBlockScrol from '../utility/useBlockScroll'
import { Link } from './link'

const PrevArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowleft nj-icon--border nj-icon--white size-48"></i>
</button>;
const NextArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--white size-48"></i>
</button>;

const settings = {
  dots: true,
  infinite: false,
  arrows: true,
  swipe: true,
  slidesToShow: 1,
  centerMode: true,
  centerPadding: '35%',
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    // {
    //   breakpoint: 1200,
    //   settings: {
    //     centerPadding: '35%'
    //   }
    // },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '10%'
      }
    },
    {
      breakpoint: 576,
      settings: {
        centerPadding: '0',
      }
    }
  ]
};

const VideoElement = ({ linkCta, children }) => {
  return linkCta ? (
    <Link className="d-flex" to={linkCta}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}

const CarouselModal = ({ show, handleClose, videos = [] }) => {

  const modalRef = useRef(null);
  const videoRef = useRef(new Array(videos.length));
  const sliderRef = useRef(null);
  settings.afterChange = useCallback(index => {
    videoRef.current.forEach((video, i) => {
      if (video) {
        if (index === i) {
          video.play();
        } else {
          video.pause();
          video.currentTime = 0;
        }
      }
    });
  }, []);
  const closeOnEscape = useCallback(e => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useBlockScrol(show);

  const goToSlide = useCallback((i) => {
    if (sliderRef.current && i < videos.length) {
      sliderRef.current.slickGoTo(i);
    }
  }, [videos.length]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
  });

  return (show ?
    (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex
      <div ref={modalRef} className="carousel-modal" onKeyDown={closeOnEscape} tabIndex={0}>
        <button onClick={handleClose} className="carousel-modal__close icon-btn" aria-label="Chiudi">
          <i className="nj-icon nj-icon-cross nj-icon--white size-48"></i>
        </button>
        <Slider {...settings} ref={slider => (sliderRef.current = slider)} className="carousel-modal__slider">
          {videos.map((item, i) => (
            <div key={i}>
              <div className="carousel-modal__card d-flex justify-content-center">
                <VideoElement linkCta={item.content.cardLinkCta && item.content.cardLinkCta.value}>
                  <video ref={elem => videoRef.current[i] = elem} autoPlay={i === 0} muted={false} playsInline={true} 
                    onEnded={() => goToSlide(i + 1)} controls>
                    <source type="video/mp4" src={item.value} />
                  </video>
                </VideoElement>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    ) : null
  );
}

export default CarouselModal;