import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import VideoModal from './video-modal'
import PlayArrowIcon from '../images/icons/material/play_arrow.svg'


const VideoWrapper = ({ videoLink, showVideo, children }) => {
  return (
    <>
      {videoLink && showVideo ? (
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
        <div className="position-relative" onClick={showVideo}>
          {children}
          {videoLink ? (
            <div className="columns__play bg-blue-corporate">
              <PlayArrowIcon className="material-svg text-white" />
            </div>
          ) : null}
        </div>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  )
}

const Columns = ({ data: { images } }) => {
  const [videoLink, setVideoLink] = useState(null);
  const closeVideo = useCallback(() => setVideoLink(null), []);
  return (
    <div className="section columns">
      <div className="container">
        <div className="row">
          {images.map((col, i) => (
            <div className="col-md-6 mb-3 mb-md-0" key={i}>
              <VideoWrapper videoLink={col.content.videoLink && col.content.videoLink.value}
                showVideo={() => setVideoLink(col.content.videoLink.value)}>
                <Img image={getImage(col.node)} className="mb-3" alt={col.node.description || ''} />
              </VideoWrapper>
              <div className="columns__text pt-3"
                dangerouslySetInnerHTML={{ __html: col.content.text.value }}>
              </div>
            </div>
          ))}
        </div>
      </div>
      <VideoModal show={!!videoLink} handleClose={closeVideo} video={videoLink} />
    </div>
  )
}

export default Columns;
export const columnsFragment = graphql`fragment ColumnsFragment on LiferayColonne{
  siteId
  articleId
  images {
    node {
      description
      gatsbyImageData(width: 600)
    }
    content {
      videoLink {
        value
      }
      text {
        value
      }
    }
  }
}`;
