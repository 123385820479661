import React, { useState, useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { Link } from './link'
import SportIcon from "../images/icons/sport.svg"


const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  swipe: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '0',
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '24px',
      }
    }
  ]
};

const MultiChoiceSelector = ({ data: { introText, choiceOptions } }) => {
  const [selected, setSelected] = useState(null);
  const selectChoice = useCallback(e => setSelected(e.currentTarget.name), []);
  const currentOption = useMemo(() => choiceOptions.find(option => option.value === selected), [choiceOptions, selected]);
  return (
    <div className="section pb-5 mb-5">
      <div className="container">
        {introText && introText.value ? (
          <div className="row">
            <div className="col-12 filters__intro" dangerouslySetInnerHTML={{__html: introText.value}}></div>
          </div>
        ): null}
        <div className="row">
          <div className="col-12 pt-4">
            <Slider {...settings} className="filters__slider">
              {choiceOptions.map(option => (
                <button key={option.value} name={option.value} onClick={selectChoice} style={{maxWidth: '150px'}}
                  className={`filters__filter d-inline-flex flex-column align-items-center ${selected === option.value ? ' filters__filter--active' : ''}`} >
                  {option.content.icon.value === 'sport' ? (
                    <SportIcon className={`filters__icon mb-1${selected === option.value ? ' filters__icon--selected':''}`} />
                  ) : (
                    <i className={`nj-icon nj-icon--blue-corporate mb-1 nj-icon-${option.content.icon.value} ${selected === option.value ? 'nj-icon--circle' : 'nj-icon--border'}`}></i>
                  )}
                  <div className={`filters__name text-center mt-1${selected === option.value ? ' text-blue-corporate' : ''}`}>
                    {option.value}
                  </div>
                </button>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {currentOption && (
        <div className="launch-box">
          <div className="container">
            <div className="row mt-5">
              {currentOption.content.cardTitle.map((card, i) => (
                <div className="col-12 col-md-6" key={i}>
                  <Link to={card.content.cardLink.value} className="launch-box__container mb-4">
                    {card.content.cardImage.node.gatsbyImageData && (
                      <Img className="launch-box__img" image={getImage(card.content.cardImage.node)}
                        alt={card.content.cardImage.node.description || ''} />
                    )}
                    <div className="launch-box__title">
                      <i className="nj-icon nj-icon-arrowright nj-icon--circle nj-icon--blue-corporate size-60"></i>
                      <h2>{card.value}</h2>
                    </div>
                  </Link>
                  <div className="text-center mx-sm-5 mx-2 mb-4 mb-md-0" dangerouslySetInnerHTML={{ __html: card.content.cardDescription.value }}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MultiChoiceSelector;
export const multiChoiceSelectorFragment = graphql`fragment MultiChoiceselectorFragment on LiferayMultichoiceselector{
  siteId
  articleId
  introText {
    value
  }
  choiceOptions {
    value
    content {
      icon {
        value
      }
      cardTitle {
        value
        content {
          cardImage {
            node {
              description
              gatsbyImageData(width: 538)
            }
          }
          cardLink {
            value
          }
          cardDescription {
            value
          }
        }
      }
    }
  }
}`;
