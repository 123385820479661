import React, { useState, useEffect, useRef, useCallback } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from 'axios'
import { Link as ScrollLink } from 'react-scroll'
import CloseIcon from '../images/icons/material/close.svg'


const FORM_ENDPOINT = process.env.GATSBY_FORM_CONTATTI_ENDPOINT;
const mandatoryFields = [
  'nome', 'cognome', 'email', 'testata', 'privacy'
];

const ComunicatiStampaForm = ({ data: { imageBox, privacyText } }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    telefono: '',
    email: '',
    testata: '',
    message: '',
    privacy: false
  });
  const onChange = useCallback((e) => {
    const { name, value, checked, type } = e.target;
    setFormData(data => ({ ...data, [name]: type === 'checkbox' ? checked : value }));
  }, []);

  const [privacyVisible, setPrivacyVisible] = useState(false);
  const showPrivacy = useCallback(() => setPrivacyVisible(true), []);
  const closePrivacy = useCallback(() => setPrivacyVisible(false), []);

  const formRef = useRef(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [errorsMap, setErrorsMap] = useState({});

  const [focusMap, setFocusMap] = useState({});
  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: false }));
  }, []);

  const validateForm = useCallback((formData) => {
    const errors = mandatoryFields.reduce((errors, field) => {
      return { ...errors, [field]: !formData[field] }
    }, {});
    // console.log('errors: ', errors);
    setErrorsMap(errors);
    const formValid = Object.values(errors).find(val => !!val) === undefined;
    if (!formValid) {
      setRemoteError(null);
    }
    return formValid;
  }, []);

  useEffect(() => {
    if (validationError) {
      const formValid = validateForm(formData);
      // console.log('formValid: ', formValid);
      if (formValid) {
        setValidationError(null);
      }
    }
  }, [validationError, formData, validateForm]);

  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return;
    }

    const formDataToSubmit = new FormData(e.target);
    if (!validateForm(formData)) {
      console.warn('Form data not valid');
      setValidationError('Completa i dati mancanti');
      return;
    }
    setValidationError(null);
    setRemoteError(null);
    const misc = {
      testata: formDataToSubmit.get('testata'),
      messaggio: formDataToSubmit.get('message')
    };
    formDataToSubmit.delete('testata');
    formDataToSubmit.delete('message');
    formDataToSubmit.set('misc', JSON.stringify(misc));

    const token = await executeRecaptcha('formCorporate');
    if (token) {
      formDataToSubmit.set('grecaptcha', token);
    }


    const queryStringSubmit = new URLSearchParams(formDataToSubmit).toString();
    console.log('About to submit: ', queryStringSubmit);
    setLoading(true);

    try {
      const { data } = await axios.post(`${FORM_ENDPOINT}`,
        queryStringSubmit,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      );
      console.log('Submitted: ', data);
      if (data.error) {
        console.warn('Si è verificato un errore: ', data);
        setRemoteError(data.error.message || 'Si è verificato un errore');
      } else {
        setSubmitted(true);
        if (formRef.current) {
          formRef.current.scrollIntoView();
        }
      }
    } catch (err) {
      console.error('error: ', err);
      if (err.response) {
        console.error('errore nella chiamata: ', err.response);
      }
      setSubmitted(false);
      setRemoteError('Si è verificato un errore');
    }

    setLoading(false);
  }, [validateForm, formData, executeRecaptcha]);

  const groupClass = (name) => `nj-form-group nj-form-group--icon-right${focusMap[name] ? ' is-focused' : ''}${formData[name] ? ' is-filled' : ''}`;
console.log(imageBox[0]);
  return (
    <div ref={formRef} className="comunicati-stampa-form">
      <div className="container mb-5 pb-5">
        <div className="row">
          {imageBox[0] && imageBox[0].node ? (
            <div className="col-lg-5 px-md-0">
              <div className="banner-co2__wrapper p-3 p-md-6 d-flex flex-column h-100">
                <Img image={getImage(imageBox[0].node)} alt={imageBox[0].node.description || ''} className="banner-co2__img" />
                {imageBox[0].content ? (
                  <div className="banner-co2__gradient-box position-relative text-white d-flex flex-column justify-content-end pl-4 pb-5 pr-2 pr-lg-3 pt-4 mt-auto">
                    {imageBox[0].content.boxTitle && imageBox[0].content.boxTitle.value ? (
                      <div className="banner-co2__title banner-co2__title--big mt-4">{imageBox[0].content.boxTitle.value}</div>
                    ): null}
                    <div className="comunicati-stampa-form__contacts mt-4">
                      {imageBox[0].content?.nominativo.map((item, index) => {
                        return (
                        <div className="mb-4">
                          {item.value && (
                          <div className="mb-3" key={index}>
                            <i className="nj-icon nj-icon-envelope nj-icon--white size-48 nj-icon--border mr-2"></i>
                            <a href={`mailto:${item.content.emailNew.value}`}>{item.value}</a>
                          </div>
                           )}
                          {item.content?.numeroTelefono.value && (
                          <div className="mb-3">
                            <i className="nj-icon nj-icon-call nj-icon--white size-48 nj-icon--border mr-2"></i>
                            <a href={`tel:${item.content.numeroTelefono.value}`} >{item.content.numeroTelefono.value}</a>
                          </div>
                          )}
                        </div>
                        )
                      })}
                    </div>
                  </div>
                ): null}
              </div>
            </div>
          ): null}
          <div className="col-lg-7 px-md-0">
            <div className="comunicati-stampa-form__content py-5 px-3 px-md-5 pr-md-7 h-100">
              <div className="row">
                <div className="col-12">
                  <h3 className="comunicati-stampa-form__title">Vuoi ricevere i nostri comunicati stampa?</h3>
                  <h4 className="comunicati-stampa-form__subtitle">Lascia i tuoi dati</h4>
                </div>
              </div>
              {submitted ? (
                <div className="row">
                  <div className="col-12 text-center">
                    <h3>Grazie per averci lasciato i tuoi dati</h3>
                  </div>
                </div>
              ) : (
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('nome')} >
                        <div className="comunicati-stampa-form__text">
                          <input type="text" className="nj-form-control" name="nome" id="comunicatiStampaFormFirstName"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.nome} />
                          <label htmlFor="comunicatiStampaFormFirstName" className="nj-label-floating">Nome</label>
                          {errorsMap.nome ? (
                            <span className="comunicati-stampa-form__warning">Specificare nome</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('cognome')}>
                        <div className="comunicati-stampa-form__text">
                          <input type="text" className="nj-form-control" name="cognome" id="comunicatiStampaFormLastName" minLength="5"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.cognome} />
                          <label htmlFor="comunicatiStampaFormLastName" className="nj-label-floating">Cognome</label>
                          {errorsMap.cognome ? (
                            <span className="comunicati-stampa-form__warning">Specificare cognome</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('telefono')}>
                        <div className="comunicati-stampa-form__text">
                          <input type="text" className="nj-form-control" name="telefono" id="comunicatiStampaFormPhone"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.telefono} />
                          <label htmlFor="comunicatiStampaFormPhone" className="nj-label-floating">Telefono</label>
                          {errorsMap.telefono ? (
                            <span className="comunicati-stampa-form__warning">Specificare telefono</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('email')}>
                        <div className="comunicati-stampa-form__text">
                          <input type="email" className="nj-form-control" name="email" id="comunicatiStampaFormEmail" autoComplete="email"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.email} />
                          <label htmlFor="comunicatiStampaFormEmail" className="nj-label-floating">Email</label>
                          {errorsMap.email ? (
                            <span className="comunicati-stampa-form__warning">Specificare email</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={groupClass('testata')}>
                        <div className="comunicati-stampa-form__text">
                          <input type="text" className="nj-form-control" name="testata" id="comunicatiStampaFormTestata" minLength="5"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.testata} />
                          <label htmlFor="comunicatiStampaFormtestata" className="nj-label-floating">Testata</label>
                          {errorsMap.testata ? (
                            <span className="comunicati-stampa-form__warning">Specificare testata</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={groupClass('message')}>
                        <div className="comunicati-stampa-form__textarea">
                          <textarea className="nj-form-control" id="comunicatiStampaFormMessage" name="message" cols="30" rows="3"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.message} >
                          </textarea>
                          <label htmlFor="comunicatiStampaFormMessage" className="nj-label-floating">Messaggio (opzionale)</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <div className="nj-checkbox">
                          <label className="comunicati-stampa-form__privacy">
                            <input type="checkbox" name="privacy" checked={formData.privacy} onChange={onChange} />
                            Acconsento al trattamento dei dati personali per le finalità
                            <span className="nj-checkbox__decorator">
                              <span className="nj-checkbox__check"></span>
                            </span>
                          </label>
                          <span> </span>
                          <ScrollLink to="comunicati-stampa-form__privacy-text" smooth={true} onClick={showPrivacy} className="contact-form__privacy-link">
                            indicate
                          </ScrollLink>
                        </div>
                        {errorsMap.privacy ? (
                          <span className="comunicati-stampa-form__warning">
                            Acconsentire al trattamento dei dati
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <input type="hidden" name="url" value="http://www.engie.it" />
                        <input type="hidden" name="contesto" value="comunicatistampa" />
                        <input type="hidden" name="status" value="0" />
                        <input type="hidden" name="comune" value="" />
                        <input type="hidden" name="indirizzo" value="" />
                        <button type="submit" className="nj-btn nj-btn--primary" disabled={loading}>
                          {loading ? (
                            <>
                              Loading...
                              <div className="nj-spinner nj-spinner--xs ml-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </>
                          ) : (
                            <span>Invia</span>
                          )}
                        </button>
                        {validationError ? (
                          <span className="ml-3 comunicati-stampa-form__warning">{validationError}</span>
                        ): null}
                        {remoteError ? (
                          <span className="ml-3 comunicati-stampa-form__warning">{remoteError}</span>
                        ): null}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-md-0">
            <span id="comunicati-stampa-form__privacy-text" className="scroll-anchor"></span>
            <div className={`comunicati-stampa-form__privacy-text${privacyVisible ? '' : ' d-none'}`}>
              <button className="comunicati-stampa-form__privacy-close" onClick={closePrivacy}>
                <CloseIcon className="material-svg" />
              </button>
              {privacyText && privacyText.value ? (
                <div className="p-3" dangerouslySetInnerHTML={{__html: privacyText.value }}></div>
              ): null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComunicatiStampaForm;
export const comunicatiStampaFormFragment = graphql`fragment Form_Comunicati_StampaFragment on LiferayForm_Comunicati_Stampa{
  siteId
  articleId
  imageBox : image {
    node {
      description
      gatsbyImageData(width: 670)
    }
    content {
      nominativo {
        content {
          emailNew {
            value
          }
          numeroTelefono {
            value
          }
        }
        value
      }
      boxTitle {
        value
      }
      phoneNumber {
        value
      }
      email {
        value
      }
    }
    
  }
  privacyText {
    value
  }
}`;
