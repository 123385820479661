import React from "react"
import { graphql } from "gatsby"
import Stories from "./stories"
import BannerCo2 from "./banner-co2"
import LaunchBox from "./launch-box"
import BoxFull from "./box-full"
import NumberBoxes from "./number-boxes"
import LaunchTiles from "./launch-tiles"
import TwoColumns from "./two-columns"
import IntroSimple from "./intro-simple"
import CorrelatedArticle2 from "./correlated-article2"
import Hero from "./hero"
import HeroIcons from "./hero-icons"
import ComunicatiStampaForm from "./comunicati-stampa-form"
import ContactFormCinisello from "./contact-form-cinisello"
import DataIntro from "./data-intro"
import Attachments from "./attachments"
import LaunchContact from "./launch-contact"
import Team from "./team"
import Quote from "./quote"
import JobForm from "./job-form"
import Carousel from "./carousel"
import FasciaSpiegazione from "./fascia-spiegazione"
import MultiChoiceSelector from "./multi-choice-selector"
import CorrelatedArticle from "./correlated-article"
import Accordions from "./accordions"
import Lanci from "./lanci"
import Columns from "./columns"
import ImageNumber from "./image-number"

import withPreview from '../utility/with-preview'
import ContactFormRifredi from "./contact-form-rifredi"
import Gallery from "./gallery"
import StickyFooterCtaLink from "./sticky-footer-cta-link"

const PageFascia = ({ data, tileList }) => {
  // console.log('fascia: ', data)
  return (
    <React.Fragment>
      {{
        LiferayStories: <Stories data={data} />,
        LiferayFasciaCO2: <BannerCo2 data={data} />,
        LiferayTileslancio: <LaunchTiles data={data} />,
        LiferayChoiceselector: <LaunchBox data={data} />,
        LiferayBannervideo: <BoxFull data={data} />,
        LiferayBoxnumeri: <NumberBoxes data={data} />,
        LiferayProdottoinformativo: <TwoColumns data={data} />,
        LiferayIntrosimple: <IntroSimple data={data} />,
        LiferayIntrodati: <DataIntro data={data} />,
        LiferayBannerinformativo: <CorrelatedArticle2 data={data} />,
        LiferaySiteHeader_Hero: <Hero data={data} />,
        LiferaySiteHeader_Hero_Icone: <HeroIcons data={data} />,
        LiferayForm_Comunicati_Stampa: <ComunicatiStampaForm data={data} />,
        LiferayAllegati: <Attachments data={data} />,
        LiferayLanciocontatti: <LaunchContact data={data} />,
        LiferayManagementTeam: <Team data={data} />,
        LiferayBannercitazione: <Quote data={data} />,
        LiferayJob_form: <JobForm data={data} />,
        LiferayProdotticorrelati: <Carousel data={data} />,
        LiferayFasciaspiegazione: <FasciaSpiegazione data={data} />,
        LiferayMultichoiceselector: <MultiChoiceSelector data={data} />,
        LiferayArticolocorrelato: <CorrelatedArticle data={data} />,
        LiferayAccordions: <Accordions data={data} />,
        LiferayForm_Contatti_Cinisello: <ContactFormCinisello data={data} />,
        LiferayForm_Contatti_Rifredi: <ContactFormRifredi data={data} />,
        LiferayLanci: <Lanci data={data} tileList={tileList} />,
        LiferayColonne: <Columns data={data} />,
        LiferayImmaginenumero: <ImageNumber data={data} />,
        LiferayGallery: <Gallery data={data} />,
        LiferayStickyFooterCtaLink: <StickyFooterCtaLink data={data} />
      }[data.__typename]}
    </React.Fragment>
  );
}

export default withPreview(PageFascia);
export const fasceFragment = graphql`fragment FasceFragment on LiferayPaginainterna{
  fasce {
    __typename
    ... StoriesFragment
    ... FasciaCO2Fragment
    ... TileslancioFragment
    ... ChoiceselectorFragment
    ... BannervideoFragment
    ... BoxnumeriFragment
    ... ProdottoinformativoFragment
    ... IntrosimpleFragment
    ... BannerinformativoFragment
    ... SiteHeader_Hero_IconeFragment
    ... Form_Comunicati_StampaFragment
    ... IntrodatiFragment
    ... AllegatiFragment
    ... LanciocontattiFragment
    ... ManagementTeamFragment
    ... BannercitazioneFragment
    ... Job_formFragment
    ... ProdotticorrelatiFragment
    ... FasciaspiegazioneFragment
    ... MultiChoiceselectorFragment
    ... ArticoloCorrelatoFragment
    ... AccordionsFragment
    ... ContactFormCiniselloFragment
    ... ContactFormRifrediFragment
    ... LanciFragment 
    ... ColumnsFragment
    ... ImmaginenumeroFragment  
    ... GalleryFragment 
    ... StickyFooterCtaLinkFragment
  }
}`;
export const fasceShortFragment = graphql`fragment FasceShortFragment on LiferayShortForm{
  fasce {
    __typename
    ... StoriesFragment
    ... FasciaCO2Fragment
    ... TileslancioFragment
    ... ChoiceselectorFragment
    ... BannervideoFragment
    ... BoxnumeriFragment
    ... ProdottoinformativoFragment
    ... IntrosimpleFragment
    ... BannerinformativoFragment
    ... IntrodatiFragment
    ... AllegatiFragment
    ... LanciocontattiFragment
    ... ManagementTeamFragment
    ... BannercitazioneFragment
    ... ProdotticorrelatiFragment
    ... FasciaspiegazioneFragment
    ... MultiChoiceselectorFragment
    ... ArticoloCorrelatoFragment
    ... AccordionsFragment
    ... ColumnsFragment
    ... ImmaginenumeroFragment 
    ... SiteHeader_Hero_IconeFragment
    ... GalleryFragment
  }
}`;
