import React, { useCallback, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import co2 from '../images/co2.png'
import { Link } from './link'
import calcRiduzione from '../utility/calc-riduzione'


const BannerCo2 = ({ data: { csvDati, rightTitle, boxNumber, linkTest } }) => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: {eq: "content/nordwood-themes.jpg"}){
          childImageSharp {
            gatsbyImageData(width: 670, quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
    `
  );

  const csvUrl = csvDati && csvDati.node && csvDati.node.publicURL;

  const [riduzione, setRiduzione] = useState(null);
  const loadRiduzione = useCallback(async () => {
    if (csvUrl) {
      try {
        const response = await fetch(csvUrl);
        const text = await response.text();
        const res = calcRiduzione(text);
        // console.log('Riduzione: ', res);
        setRiduzione(res || boxNumber.value);
      } catch (err) {
        console.error('Errore nel recupero del csv: ', err);
        setRiduzione(boxNumber.value);
      }
    } else {
      setRiduzione(boxNumber.value);
    }
  }, [csvUrl, boxNumber.value]);
  useEffect(() => {
    if (!riduzione) {
      loadRiduzione();
    }
  }, [riduzione, loadRiduzione]);

  return (
    <div className="banner-co2">
      <div className="container">
        <div className="row pb-5 mb-5">
          <div className="col-lg-6">
            <Link to={linkTest.value} className="banner-co2__box h-100 p-4 p-md-6">
              <Img image={getImage(background.childImageSharp)} alt="Banner CO2" className="banner-co2__img" />
              <div className="banner-co2__gradient-box position-relative text-white w-100 h-100 d-flex flex-column justify-content-end pl-4 pb-5 pr-6 pt-4">
                <div className="banner-co2__saving d-lg-none">
                  <div className="banner-co2__title">{rightTitle.value}</div>
                  <div className="d-flex">
                    <div className="banner-co2__amount">-{riduzione ? riduzione : boxNumber.value}</div>
                    <i className="nj-icon nj-icon-north-earth nj-icon--white"></i>
                  </div>
                  <div className="d-flex">
                    <div className="banner-co2__unit">tonnellate di</div>
                    <img src={co2} alt="CO2" className="banner-co2__co2" />
                  </div>
                </div>
                <div className="banner-co2__title">
                  Quanta CO<sub>2</sub> immetti nell'ambiente?</div>
                <div className="banner-co2__subtitle mt-2 mb-4">Rispondi a 24 brevi domande su: Casa, Mobilità e Lifestyle e scopri il tuo Carbon Footprint. </div>
                <button className="nj-btn nj-btn--light align-self-start">Inizia il test</button>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="banner-co2__box text-white h-100 p-5 bg-blue-corporate">
              <div className="banner-co2__title">{rightTitle.value}</div>
              <div className="pt-3 d-flex">
                <div className="banner-co2__amount">-{riduzione ? riduzione : ''}</div>
                <i className="nj-icon nj-icon-north-earth nj-icon--white"></i>
              </div>
              <div className="d-flex">
                <div className="banner-co2__unit">tonnellate di</div>
                <img src={co2} alt="CO2" className="banner-co2__co2" />
              </div>
              <div>
                <p>
                  La riduzione delle emissioni di CO2 in atmosfera è il risultato della produzione di energia elettrica di impianti ENGIE
                  che utilizzano fonti rinnovabili (solare, eolico, biomasse)
                  e delle azioni di efficienza energetica messe in atto.
                </p>
                <p>
                  Nel 2023 ENGIE ha contribuito al risparmio di 507.939 tonnellate di CO2.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerCo2;
export const fasciaCO2Fragment = graphql`fragment FasciaCO2Fragment on LiferayFasciaCO2{
  siteId
  articleId
  csvDati {
    node {
      publicURL
    }
  }
  boxNumber {
    value
  }
  rightTitle {
    value
  }
  linkTest {
    value
  }
}`;