import React, { useState, useEffect, useRef, useCallback } from 'react'
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import axios from "axios"
import { Link as ScrollLink } from 'react-scroll'
import CloseIcon from '../images/icons/material/close.svg'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


const FORM_ENDPOINT = process.env.GATSBY_FORM_CONTATTI_ENDPOINT;
const mandatoryFields = [
  'nome', 'cognome', 'email', 'misc', 'indirizzo', 'privacy'
];

const ContactFormCinisello = ({ data: { image, privacyText }}) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    telefono: '',
    email: '',
    indirizzo: '',
    misc: '',
    privacy: false
  });
  const onChange = useCallback((e) => {
    const { name, value, checked, type } = e.target;
    setFormData(data => ({ ...data, [name]: type === 'checkbox' ? checked : value }));
  }, []);

  const [privacyVisible, setPrivacyVisible] = useState(false);
  const showPrivacy = useCallback(() => setPrivacyVisible(true), []);
  const closePrivacy = useCallback(() => setPrivacyVisible(false), []);

  const formRef = useRef(null);

  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [errorsMap, setErrorsMap] = useState({});

  const [focusMap, setFocusMap] = useState({});
  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: false }));
  }, []);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validateForm = useCallback((formData) => {
    const errors = mandatoryFields.reduce((errors, field) => {
      return { ...errors, [field]: !formData[field] }
    }, {});
    // console.log('errors: ', errors);
    setErrorsMap(errors);
    const formValid = Object.values(errors).find(val => !!val) === undefined;
    if (!formValid) {
      setRemoteError(null);
    }
    return formValid;
  }, []);

  useEffect(() => {
    if (validationError) {
      const formValid = validateForm(formData);
      // console.log('formValid: ', formValid);
      if (formValid) {
        setValidationError(null);
      }
    }
  }, [validationError, formData, validateForm]);

  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData(e.target);
    if (!validateForm(formData)) {
      console.warn('Form data not valid');
      setValidationError('Completa i dati mancanti');
      return;
    }
    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('formCorporate');
    if (token) {
      formDataToSubmit.set('grecaptcha', token);
    }


    setValidationError(null);
    setRemoteError(null);
    const queryStringSubmit = new URLSearchParams(formDataToSubmit).toString();
    console.log('About to submit: ', queryStringSubmit);
    setLoading(true);

    try {
      const { data } = await axios.post(`${FORM_ENDPOINT}`,
        queryStringSubmit,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      );
      console.log('Submitted: ', data);
      if (data.error) {
        console.warn('Si è verificato un errore: ', data);
        setRemoteError(data.error.message || 'Si è verificato un errore');
      } else {
        setSubmitted(true);
        if (formRef.current) {
          formRef.current.scrollIntoView();
        }
      }
    } catch (err) {
      console.error('error: ', err);
      if (err.response) {
        console.error('errore nella chiamata: ', err.response);
      }
      setSubmitted(false);
      setRemoteError('Si è verificato un errore');
    }
    setLoading(false);
  }, [validateForm, formData, executeRecaptcha]);

  const groupClass = (name) => `nj-form-group nj-form-group--icon-right${focusMap[name] ? ' is-focused' : ''}${formData[name] ? ' is-filled' : ''}${errorsMap[name] ? ' has-warning': ''}`;

  return (
    <div className="contact-form">
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-5 px-md-0">
            <div className="contact-form__wrapper p-3 p-md-6 d-flex flex-column h-100">
              {image && image.node && image.node.gatsbyImageData ? (
                <Img image={getImage(image.node)} alt={image.node.description || ''} 
                  className="contact-form__img" />
              ): null}
              {image && image.content ? (
                <div className="contact-form__gradient-box position-relative text-white d-flex flex-column justify-content-end pl-4 pb-5 pr-4 pt-4 mt-auto">
                  {image.content.boxTitle && image.content.boxTitle.value ? (
                    <div className="contact-form__box-title contact-form__box-title--big mt-4">
                      {image.content.boxTitle.value}
                    </div>
                  ): null}
                  {image.content.boxText && image.content.boxText.value ? (
                    <div className="contact-form__box-subtitle mt-2" 
                      dangerouslySetInnerHTML={{__html: image.content.boxText.value }}></div>
                  ): null}
                </div>
              ): null}
            </div>
          </div>
          <div ref={formRef} className="col-lg-7 px-md-0">
            <div className="contact-form__content py-5 px-3 px-md-5 pr-md-7 h-100">
              <div className="row">
                <div className="col-12">
                  <h3 className="contact-form__title">Lasciaci i tuoi dati</h3>
                </div>
              </div>
              {submitted ? (
                <div className="row">
                  <div className="col-12 text-center mt-lg-5 pt-lg-5">
                    <h3>Grazie per averci lasciato i tuoi dati</h3>
                  </div>
                </div>
              ) : (
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('nome')} >
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="nome" id="contactFormNome"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.nome} />
                          <label htmlFor="contactFormNome" className="nj-label-floating">Nome</label>
                        </div>
                      </div>
                      {errorsMap.nome ? (
                        <span className="contact-form__warning">Specificare nome</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('cognome')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="cognome" id="contactFormCognome" minLength="5"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.cognome} />
                          <label htmlFor="contactFormCognome" className="nj-label-floating">Cognome</label>
                          {/* <span className="nj-help">Your lastname must have 5 characters</span> */}
                        </div>
                      </div>
                      {errorsMap.cognome ? (
                        <span className="contact-form__warning">Specificare cognome</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('email')}>
                        <div className="contact-form__text">
                          <input type="email" className="nj-form-control" name="email" id="contactFormEmail" autoComplete="email"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.email} />
                          <label htmlFor="contactFormEmail" className="nj-label-floating">Email address</label>
                          {/* <span className="nj-help">We'll never share your email with anyone else.</span> */}
                        </div>
                      </div>
                      {errorsMap.email ? (
                        <span className="contact-form__warning">Specificare email</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('telefono')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="telefono" id="contactFormTelefono"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.telefono} />
                          <label htmlFor="contactFormTelefono" className="nj-label-floating">Numero di telefono</label>
                          {/* <span className="nj-help"></span> */}
                        </div>
                      </div>
                      {/* {errorsMap.telefono ? (
                        <span className="contact-form__warning">Specificare telefono</span>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('misc')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="misc" 
                            id="contactFormrCiniselloMisc"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.misc} />
                          <label htmlFor="contactFormrCiniselloMisc" className="nj-label-floating">Nome condominio</label>
                        </div>
                      </div>
                      {errorsMap.misc ? (
                        <span className="contact-form__warning">Specificare nome condominio</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('indirizzo')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="indirizzo" id="contactFormCiniselloIndirizzo"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.indirizzo} />
                          <label htmlFor="contactFormCiniselloIndirizzo" className="nj-label-floating">Indirizzo</label>
                        </div>
                      </div>
                      {errorsMap.indirizzo ? (
                        <span className="contact-form__warning">Specificare indirizzo</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group is-filled">
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="comune" id="contactFormCiniselloComune"
                            value="Cinisello Balsamo" readOnly={true} />
                          <label htmlFor="contactFormCiniselloComune" className="nj-label-floating">Comune</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <div className="nj-checkbox">
                          <label className="contact-form__privacy">
                            <input type="checkbox" name="privacy" value="true" 
                              checked={formData.privacy} onChange={onChange} />
                            Acconsento al trattamento dei dati personali per le finalità
                            <span className="nj-checkbox__decorator">
                              <span className="nj-checkbox__check"></span>
                            </span>
                          </label>
                          <span> </span>
                          <ScrollLink to="contact-form-cinisello-privacy-text" smooth={true} onClick={showPrivacy} className="contact-form__privacy-link">
                            indicate
                          </ScrollLink>
                        </div>
                      </div>
                      {errorsMap.privacy ? (
                        <span className="contact-form__warning">
                          Acconsentire al trattamento dei dati
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <input type="hidden" name="url" value="http://www.engie.it" />
                        <input type="hidden" name="contesto" value="cinisello" />
                        <input type="hidden" name="status" value="0" />
                        <button type="submit" className="nj-btn nj-btn--primary" disabled={loading}>
                          {loading ? (
                            <>
                              Loading...
                            <div className="nj-spinner nj-spinner--xs ml-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </>
                          ) : (
                              <span>Invia</span>
                            )}
                        </button>
                        {validationError ? (
                          <span className="ml-3 contact-form__warning">{validationError}</span>
                        ): null}
                        {remoteError ? (
                          <span className="ml-3 contact-form__warning">{remoteError}</span>
                        ): null}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-md-0">
            <span id="contact-form-cinisello-privacy-text" className="scroll-anchor"></span>
            <div className={`contact-form__privacy-text${privacyVisible ? '' : ' d-none'}`}>
              <button className="contact-form__privacy-close" onClick={closePrivacy}>
                <CloseIcon className="material-svg" />
              </button>
              {privacyText && privacyText.value ? (
                <div className="p-3" dangerouslySetInnerHTML={{__html: privacyText.value }}></div>
              ): null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactFormCinisello;
export const contactFormFragment = graphql`fragment ContactFormCiniselloFragment on LiferayForm_Contatti_Cinisello{
  siteId
  articleId
  image {
    node {
      description
      gatsbyImageData(width: 670)
    }
    content {
      boxTitle {
        value
      }
      boxText {
        value
      }
    }
  }
  privacyText {
    value
  }
}`;
