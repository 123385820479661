import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; 
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const calcRiduzione = data => {
  const rows = data.split('\r\n').slice(1).filter(item => !!item).map(item => {
    const [date, value] = item.split(';');
    return { date, value };
  });
  // console.log('rows: ', rows);
  
  const now = dayjs.tz(new Date(), 'Europe/Rome');
  const ore = now.get('hour');
  const minuti = now.get('minute');
  const secondi = now.get('second');
  const oggi = dayjs.tz(new Date(), 'Europe/Rome').startOf('day');
  const ieri = dayjs.tz(new Date(), 'Europe/Rome').add(-1, 'day').startOf('day');
  // console.log('ore, minuti, secondi: ', ore, minuti, secondi);
  const datoOggi = rows.find(item => item.date === oggi.format('DD/MM/YYYY'));
  const datoIeri = rows.find(item => item.date === ieri.format('DD/MM/YYYY'));
  // console.log('oggi: ', parseInt(datoOggi.value));
  // console.log('ieri: ', parseInt(datoIeri.value));
  
  if (datoOggi && datoIeri) {
    const riduzione = parseInt(datoIeri.value) + (parseInt(datoOggi.value) - parseInt(datoIeri.value))
      *(ore*3600 + minuti*60 + secondi)/(24*60*60);
    return Math.round(riduzione);
  }
  return null;
}

export default calcRiduzione;
