import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'


const ImageNumber = ({ data: { image, number, text }}) => {
  return (
    <div className="section image-number">
      <div className="container">
        <div className="row mt-4">
          <div className="col-12">
            <Img image={getImage(image.node)} alt={image.node.description || ''} />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 d-flex flex-column flex-md-row">
            <div className="image-number__bignum pr-5 text-center text-md-left mb-3 mb-md-0">{number.value}</div>
            <div className="image-number__text flex-fill"
              dangerouslySetInnerHTML={{ __html: text.value }}>
            </div>
          </div>
        </div>
      </div >
    </div>
  )
}

export default ImageNumber;
export const tileFragment = graphql`fragment ImmaginenumeroFragment on LiferayImmaginenumero{
  siteId
  articleId
  image {
    node {
      description
      gatsbyImageData(width: 1108)
    }
  }
  number {
    value
  }
  text {
    value
  }
}`