import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { Link } from './link'


const PrevArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowleft nj-icon--border nj-icon--blue-corporate size-40"></i>
</button>;
const NextArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--blue-corporate size-40"></i>
</button>;

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  swipe: true,
  slidesToShow: 2,
  centerMode: true,
  centerPadding: '30px',
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const NumberBoxes = ({ data: { mainTitle, cardTitle, btnText } }) => {
  return (
    <div className={`number-boxes${cardTitle.length < 3 || cardTitle.length%3 !== 1 ? '': ' number-boxes--odd'} pb-5 mb-5`}>
      <div className="container">
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1 px-lg-7">
              <h4 className="number-boxes__title font-weight-light text-center mb-4" dangerouslySetInnerHTML={{__html: mainTitle.value }}></h4>
            </div>
          </div>
        ): null}
        <div className={`number-boxes__wrapper d-none d-lg-flex justify-content-center flex-wrap`}>
          {cardTitle.map((box, i) => (
            <div className="number-boxes__box mb-4 p-3" key={i}>
              <p className="number-boxes__text font-weight-black mb-0">{box.value}</p>
              <div className="number-boxes__text" dangerouslySetInnerHTML={{ __html: box.content.cardDescription.value }}></div>
              <h4 className="number-boxes__number d-flex justify-content-center align-items-center">{i + 1}</h4>
            </div>
          ))}
        </div>
        <Slider className="number-boxes__slider d-lg-none" {...settings}>
          {cardTitle.map((box, i) => (
            <div key={i}>
              <div className="number-boxes__box p-3">
                <p className="number-boxes__text font-weight-black mb-0">{box.value}</p>
                <div className="number-boxes__text" dangerouslySetInnerHTML={{ __html: box.content.cardDescription.value }}></div>
                <h4 className="number-boxes__number d-flex justify-content-center align-items-center">{i + 1}</h4>
              </div>
            </div>
          ))}
        </Slider>
        {btnText && btnText.value && btnText.content && btnText.content.btnLink && btnText.content.btnLink.value ? (
          <div className="row">
            <div className="col-12 text-lg-center">
              <Link to={btnText.content.btnLink.value} className="nj-btn nj-btn--outline-primary">{btnText.value}</Link>
            </div>
          </div>
        ): null}
      </div>
    </div>
  );
}

export default NumberBoxes;
export const numberBoxesFragment = graphql`fragment BoxnumeriFragment on LiferayBoxnumeri{
  siteId
  articleId
  mainTitle {
    value
  }
  cardTitle {
    value
    content {
      cardDescription {
        value
      }
    }
  }
  btnText {
    value
    content {
      btnLink {
        value
      }
    }
  }
}`;
