import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import { Link } from './link'


const Quote = ({ data: { mainTitle, personImage, background, btnText } }) => {
  return (
    <div className="quote container mb-5 pb-5">
      <div className="row">
        <div className="col-12 text-center">
          {mainTitle && mainTitle.value ? (
            <h2 className="carousel__title">{mainTitle.value}</h2>
          ): null}
          {mainTitle && mainTitle.content && mainTitle.content.mainSubtitle && mainTitle.content.mainSubtitle.value ? (
            <div className="quote__subtitle" dangerouslySetInnerHTML={{__html: mainTitle.content.mainSubtitle.value }}>
            </div>
          ): null}
        </div>
      </div>
      {background ? (
        <div className="row">
          <div className="col-12">
            <div className="quote__container">
              {background.node ? (
                <Img className="quote__bg" image={getImage(background.node)} 
                  alt={background.node.description || ''} />
              ): null }
              {background.content && background.content.citazione ? (
                <div className={`quote__text ${personImage? 'quote__text--person':''}`} dangerouslySetInnerHTML={{__html: background.content.citazione.value}}></div>
              ):null }
            </div>
            {personImage ? (
              <div className="quote__person text-center">
                {personImage.node && personImage.node.resize ? (
                  <img className="quote__person-img mb-2" src={personImage.node.resize.src}
                    alt={personImage.node.description || ''} />
                ): null}
                {personImage.content && personImage.content.personDescription && personImage.content.personDescription.value ? (
                  <p>{personImage.content.personDescription.value}</p>
                ): null}
              </div>
            ): null}
          </div>
        </div>
      ):null }
      {btnText && btnText.value && btnText.content && btnText.content.btnLink && btnText.content.btnLink.value ? (
        <div className="row">
          <div className="col-12 text-center">
            <Link to={btnText.content.btnLink.value} className="nj-btn nj-btn--primary mt-3">{btnText.value}</Link>
          </div>
        </div>
      ): null}
    </div>
  );
}

export default Quote;
export const quoteFragment = graphql`fragment BannercitazioneFragment on LiferayBannercitazione{
  siteId
  articleId
  mainTitle {
    value
    content {
      mainSubtitle {
        value
      }
    }
  }
  personImage {
    node {
      description
      resize(width: 158, height: 158) {
        src
      }
    }
    content {
      personDescription {
        value
      }
    }
  }
  background {
    node {
      description
      gatsbyImageData(width: 1440)
    }
    content {
      citazione {
        value
      }
    }
  }
  btnText {
    value
    content {
      btnLink {
        value
      }
    }
  }
}`;