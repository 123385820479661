import React from 'react'
import { graphql } from 'gatsby'


const LaunchContact = ({ data: { titleLeft, titleRight }}) => {
  const iconSx = (titleLeft.content && titleLeft.content.iconSx && titleLeft.content.iconSx.value) || 'info';
  const iconDx = (titleRight.content && titleRight.content.iconSx && titleRight.content.iconDx.value) || 'smartphone-hand';
  return (
    <div className="launch-contact mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <div className="launch-contact__blue px-4 px-xl-7 px-lg-5 px-md-7 py-5 h-100">
              <h3>
                <i className={`nj-icon nj-icon-${iconSx} size-60 nj-icon--white nj-icon--border mr-2`}></i>
                {titleLeft && titleLeft.value}
              </h3>
              {titleLeft && titleLeft.content && titleLeft.content.textLeft && titleLeft.content.textLeft.value ? (
                <div dangerouslySetInnerHTML={{__html: titleLeft.content.textLeft.value}}></div>
              ): null}
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="launch-contact__green px-4 px-xl-7 px-lg-5 px-md-7 py-5 h-100">
              <h3>
                <i className={`nj-icon nj-icon-${iconDx} size-60 nj-icon--green-riogrande nj-icon--border mr-2`}></i>
                {titleRight && titleRight.value}
              </h3>
              {titleRight && titleRight.content && titleRight.content.textRight && titleRight.content.textRight.value ? (
                <div dangerouslySetInnerHTML={{__html: titleRight.content.textRight.value}}></div>
              ): null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LaunchContact;
export const launchContactFragment = graphql`fragment LanciocontattiFragment on LiferayLanciocontatti{
  siteId
  articleId
  titleLeft {
    value
    content {
      textLeft {
        value
      }
      iconSx {
        value
      }
    }
  }
  titleRight {
    value
    content {
      textRight {
        value
      }
      iconDx {
        value
      }
    }
  }
}`;
