import React from 'react'
import { graphql } from 'gatsby'
import TeamCard from './elements/team-card'


const Team = ({ data: { mainIcon, mainTitle, mainSubtitle, topName, personName }}) => {
  return (
    <div className="team mb-2 pb-5">
      <div className="container">
        {mainTitle && mainTitle.value &&
          <div className="row">
            <div className="col-12 text-center">
              {mainIcon && mainIcon.value ? (
                <i className={`nj-icon nj-icon-${mainIcon.value} nj-icon--blue-corporate size-126`}></i>
              ): null}
              <h3 className="carousel__title">{mainTitle.value}</h3>
              {mainSubtitle && mainSubtitle.value ? (
                <div className="team__subtitle" dangerouslySetInnerHTML={{__html: mainSubtitle.value}}></div>
              ): null}
              {/* <p className="text-blue-corporate font-weight-bold mb-5">
                Il Management Team di ENGIE in Italia è composto da:
              </p> */}
            </div>
          </div>
        }
        {topName && topName.length ? (
          <div className="row">
            {topName.map((item,index) => {
              return (
                <div key={index} className={`col-12 col-md-6`}>
                  <TeamCard person={item} top={true} />
                </div>
              )
            })}
          </div>
        ): null}
        {personName && personName.length ? (
          <div className="row">
            {personName.map((item,index) => {
              return (
                <div key={index} className={`col-6 col-md-4`}>
                  <TeamCard person={item}/>
                </div>
              )
            })}
          </div>
        ): null}
      </div>
    </div>
  );
}

export default Team;
export const teamFragment = graphql`fragment ManagementTeamFragment on LiferayManagementTeam{
  siteId
  articleId
  mainIcon {
    value
  }
  mainTitle {
    value
  }
  mainSubtitle {
    value
  }
  topName {
    value
    content {
      topRole {
        value
      }
      topImgHd {
        node {
          publicURL
        }
      }
      topPic {
        node {
          description
          publicURL
          fileExtension
          gatsbyImageData(width: 538)
        }
      }
      mainLinkedinLink {
        value
      }
    }
  }
  personName {
    value
    content {
      role {
        value
      }
      imgHd {
        node {
          publicURL
        }
      }
      picture {
        node {
          description
          publicURL
          fileExtension
          gatsbyImageData(width: 350)
        }
      }
      linkedinLink {
        value
      }
    }
  }
}`;
