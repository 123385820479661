import React, { useState, useCallback, useMemo } from "react";
import { graphql } from "gatsby";
import {
  GatsbyImage as Img,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image";
import VideoModal from "./video-modal";
import PlayArrowIcon from "../images/icons/material/play_arrow.svg";

const textCol = (offsetData) => {
  const offset = offsetData && offsetData.length ? offsetData[0] : 1;
  return (
    {
      0: "col-12",
      1: "col-lg-10 offset-lg-1",
      2: "col-lg-8 offset-lg-2",
    }[offset] || "col-lg-10 offset-lg-1"
  );
};

const IntroSimple = ({ data: { imageDesktop, imageMobile, mainTitle } }) => {
  const sources = useMemo(() => {
    if (!imageDesktop) return null;
    return imageMobile
      ? withArtDirection(getImage(imageDesktop.node), [
          {
            media: "(max-width: 576px)",
            image: getImage(imageMobile.node),
          },
        ])
      : getImage(imageDesktop.node);
  }, [imageMobile, imageDesktop]);

  // Gestione eventuale video
  const videoLink =
    imageDesktop &&
    imageDesktop.content &&
    imageDesktop.content.videoLink &&
    imageDesktop.content.videoLink.value;
  const [videoVisible, setVideoVisible] = useState(false);
  const showVideo = useCallback(() => {
    if (videoLink) {
      setVideoVisible(true);
    }
  }, [videoLink]);
  const closeVideo = useCallback(() => setVideoVisible(false), []);

  return (
    <div className="section intro-simple mb-5 pb-5">
      <div className="container">
        {sources ? (
          <div className="row mb-5">
            <div className="col-12">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className={`intro-simple__img${
                  videoLink ? " intro-simple__img--video" : ""
                }`}
                onClick={showVideo}
              >
                <Img
                  image={sources}
                  alt={
                    (imageDesktop &&
                      imageDesktop.node &&
                      imageDesktop.node.description) ||
                    ""
                  }
                  className="intro-simple__image"
                />
                {videoLink ? (
                  <div className="intro-simple__play bg-blue-corporate">
                    <PlayArrowIcon className="material-svg text-white" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div
              className={textCol(
                mainTitle.content &&
                  mainTitle.content.sideOffset &&
                  mainTitle.content.sideOffset.value
              )}
            >
              <h2 className="section__title">{mainTitle.value}</h2>
            </div>
          </div>
        ) : null}
        {mainTitle && mainTitle.content && mainTitle.content.mainText ? (
          <div className="row">
            <div
              className={textCol(
                mainTitle.content &&
                  mainTitle.content.sideOffset &&
                  mainTitle.content.sideOffset.value
              )}
            >
              <div
                className="intro-simple__content"
                dangerouslySetInnerHTML={{
                  __html: mainTitle.content.mainText.value,
                }}
              ></div>
            </div>
          </div>
        ) : null}
      </div>
      <VideoModal
        show={videoVisible}
        handleClose={closeVideo}
        video={videoLink}
      />
    </div>
  );
};

export default IntroSimple;
export const introSimpleFragment = graphql`
  fragment IntrosimpleFragment on LiferayIntrosimple {
    siteId
    articleId
    imageDesktop {
      node {
        description
        gatsbyImageData(width: 1108)
      }
      content {
        videoLink {
          value
        }
      }
    }
    imageMobile {
      node {
        gatsbyImageData(width: 540)
      }
    }
    mainTitle {
      value
      content {
        mainText {
          value
        }
        sideOffset {
          value
        }
      }
    }
  }
`;
