import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import ExpandLessIcon from '../images/icons/material/expand_less.svg'
import ExpandMoreIcon from '../images/icons/material/expand_more.svg'


const Accordions = ({ data: { id, mainTitle, accordion }}) => {
  const [collapseMap, setCollapseMap] = useState({});
  const toggleCollapse = useCallback((key) => setCollapseMap(map => ({ ...map, [key]: !map[key] })), []);
  return (
    <div className="accordions mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {mainTitle && mainTitle.value ? (
              <div className="accordions__title" dangerouslySetInnerHTML={{ __html: mainTitle.value}}></div>
            ) : null}
            {accordion && accordion.length ? (
              <ul className="accordions__list mt-4">
                {accordion.map((item, index) => {
                  return (
                    <li key={index}>
                      <button className="accordions__item" onClick={() => toggleCollapse(`${id}-${index}`)}
                        aria-controls={`${id}-${index}`} aria-expanded={collapseMap[`${id}-${index}`]}>
                        {item.value}
                        {collapseMap[`${id}-${index}`] ? 
                          <ExpandLessIcon className="material-svg" /> :
                          <ExpandMoreIcon className="material-svg" />
                        }
                      </button>
                      <div id={`${id}-${index}`} dangerouslySetInnerHTML={{__html: item.content.text.value }}
                        className={`accordions__content px-2${collapseMap[`${id}-${index}`] ? ' accordions__content--expanded' : ''}`}></div>
                    </li>
                  )
                })}
              </ul>
            ): null}
          </div>
        </div>
      </div>
    </div>
  )

}

export default Accordions;
export const accordionsFragment = graphql`fragment AccordionsFragment on LiferayAccordions{
  siteId
  articleId
  id
  mainTitle {
    value
  }
  accordion {
    value
    content {
      text {
        value
      }
    }
  }
}`;