import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link } from '../components/link'


const LaunchTiles = ({ data: { tileImage }}) => {
  return (
    <div className="launch-tiles">
      <div className="container">
        <div className="row pb-5 mb-5">
          {tileImage.map((tile, i) => (
            <div className="col-lg-6" key={i}>
              <Link to={tile.content.buttonText.content.buttonLink.value} className="launch-tiles__tile d-flex flex-column justify-content-end p-6 mb-2">
                <Img image={getImage(tile.node)} className="launch-tiles__img"
                  alt={tile.content.tileTitle.value || tile.node.description || ''}  />
                <div className="launch-tiles__box p-4">
                  <h3 className="launch-tiles__title mb-1">{tile.content.tileTitle.value}</h3>
                  <p className="launch-tiles__text">{tile.content.tileDescription.value}</p>
                  <button className="nj-btn nj-btn--light mt-3">{tile.content.buttonText.value}</button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LaunchTiles;
export const launchTilesFragment = graphql`fragment TileslancioFragment on LiferayTileslancio{
  siteId
  articleId
  tileImage {
    node {
      description
      gatsbyImageData(width: 465)
    }
    content {
      tileTitle {
        value
      }
      tileDescription {
        value
      }
      buttonText {
        value
        content {
          buttonLink {
            value
          }
        }
      }
    }
  }
}`;
