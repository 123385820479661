import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'
import { Link as ScrollLink } from 'react-scroll'
import NjSelect from './nj-select'
import provinceList from '../data/job-form/province.json'
import areeList from '../data/job-form/area-funzionale.json'
import titoliList from '../data/job-form/titolo-studio.json'
import regioniList from '../data/job-form/regioni.json'
import CloseIcon from '../images/icons/material/close.svg'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";



const FORM_ENDPOINT = process.env.GATSBY_FORM_HR_ENDPOINT;
const mandatoryFields = [
  'firstName', 'lastName', 'phoneNumber', 'emailAddress', 'livesin',
  'livesinId', 'region',
  'functionalAreaCategoryId', 'qualificationCategoryId', 'cv',
  'privacyAgreement'
];

const JobForm = ({ data: { boxTitle, boxSubtitle, privacyText, typeForm } }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    livesin: '',
    livesinId: '',
    region: '',
    functionalAreaCategoryId: typeForm?.value?.[0] === 'manutentore' ? areeList.areaTecnica[0].value : '',
    qualificationCategoryId: typeForm?.value?.[0] === 'manutentore' ? titoliList.titoliStudioTecnico[0].value : '',
    messaggio: '',
    cv: null,
    privacyAgreement: false
  });

  const formRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  
  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [errorsMap, setErrorsMap] = useState({});

  const onChange = useCallback((e) => {
    const { name, value, checked, type } = e.target;
    setFormData(data => ({ ...data, [name]: type === 'checkbox' ? checked : value }));
  }, []);

  const [privacyVisible, setPrivacyVisible] = useState(false);
  const showPrivacy = useCallback(() => setPrivacyVisible(true), []);
  const closePrivacy = useCallback(() => setPrivacyVisible(false), []);

  const opzioniProvince = useMemo(() => {
    if (formData.livesin) {
      return provinceList.province.filter(item => item.nome.toLowerCase().includes(formData.livesin.toLowerCase()));
    }
    return [];
  }, [formData.livesin]);

  const setProvincia = useCallback((val) => {
    const provincia = provinceList.province.find(item => item.nome === val);
    if (provincia) {
      setFormData(currentData => ({
        ...currentData,
        livesin: val,
        livesinId: provincia.nome,
        region: regioniList.regioni.find(region => region.id === provincia.id_regione).nome
      }));
    }
  }, []);
  const setArea = useCallback((val) => setFormData(value => ({ ...value, functionalAreaCategoryId: val })), []);
  const setTitolo = useCallback((val) => setFormData(value => ({ ...value, qualificationCategoryId: val })), []);

  const [focusMap, setFocusMap] = useState({});

  const validateForm = useCallback((formData) => {
    const errors = mandatoryFields.reduce((errors, field) => {
      return { ...errors, [field]: !formData[field] }
    }, {});
    errors.livesin = !formData.livesin || formData.livesin !== formData.livesinId;
    // console.log('errors: ', errors);
    setErrorsMap(errors);
    const formValid = Object.values(errors).find(val => !!val) === undefined;
    if (!formValid) {
      setRemoteError(null);
    }
    return formValid;
  }, []);

  useEffect(() => {
    if (validationError) {
      const formValid = validateForm(formData);
      // console.log('formValid: ', formValid);
      if (formValid) {
        setValidationError(null);
      }
    }
  }, [validationError, formData, validateForm]);

  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: false }));
  }, []);

  useEffect(() => {
    setErrorsMap(current => ({
      ...current,
      livesin: formData.livesin && formData.livesin !== formData.livesinId && !focusMap.livesin
    }))
  }, [focusMap.livesin, formData.livesin, formData.livesinId]);

  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData(e.target);
    if (!validateForm(formData)) {
      console.warn('Form data not valid');
      setValidationError('Completa i dati mancanti');
      return;
    }

    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('formCorporate');
    if (token) {
      formDataToSubmit.set('grecaptcha', token);
    }


    setValidationError(null);
    setRemoteError(null);
    const data = [...formDataToSubmit.entries()];
    console.log('About to submit: ', data);
    setLoading(true);
    try {
      const { data } = await axios.post(`${FORM_ENDPOINT}`,
        formDataToSubmit,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      if (data.success) {
        console.log('Submitted: ', data);
        setSubmitted(true);
        if (formRef.current) {
          formRef.current.scrollIntoView();
        }
      } else {
        console.warn('Si è verificato un errore: ', data);
        setRemoteError(data.message || 'Si è verificato un errore');
      }
    } catch (err) {
      console.error('error: ', err);
      if (err.response) {
        console.error('errore nella chiamata: ', err.response);
      }
      setSubmitted(false);
      setRemoteError('Si è verificato un errore');
    }
    setLoading(false);
  }, [validateForm, formData, executeRecaptcha ]);

  const fileRef = useRef(null);
  const onChangeFile = useCallback(() => {
    if (fileRef.current) {
      setFormData(data => ({ ...data, cv: fileRef.current.files && fileRef.current.files[0] }));
    }
  }, []);
  const cancelFile = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.value = '';
      setFormData(data => ({ ...data, cv: null }));
    }
  }, []);

  const groupClass = (name) => {
    return `nj-form-group${focusMap[name] ? ' is-focused' : ''}${formData[name] ? ' is-filled' : ''}${errorsMap[name] ? ' has-warning' : ''}`;
  }

  return (
    <div ref={formRef} className="job-form py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <i className="nj-icon nj-icon-expert nj-icon--blue-corporate size-96"></i>
            {boxTitle && boxTitle.value ? <h3 className="job-form__title">{boxTitle.value}</h3> : null}
            {boxSubtitle && boxSubtitle.value ? (
              <p className="text-blue-corporate font-weight-bold mb-6">{boxSubtitle.value}</p>
            ) : null}
          </div>
        </div>
        {submitted ? (
          <div className="row">
            <div className="col-12 text-center">
              <h3>Grazie per averci lasciato i tuoi dati</h3>
            </div>
          </div>
        ) : (
            <form onSubmit={submit}>
              {typeForm?.value?.[0] === "manutentore" && <input type="hidden" name="functionalAreaCategoryId" value={areeList.areaTecnica[0].value} /> }
              {typeForm?.value?.[0] === "manutentore" && <input type="hidden" name="qualificationCategoryId" value={titoliList.titoliStudioTecnico[0].value} /> }
              <div className="row">
                <div className="col-md-6">
                  <div className={groupClass('firstName')} >
                    <div className="job-form__text">
                      <input type="text" className="nj-form-control" name="firstName" id="jobFormFirstName"
                        onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.firstName} />
                      <label htmlFor="jobFormFirstName" className="nj-label-floating">Nome</label>
                      {errorsMap.firstName ? (
                        <span className="job-form__warning">Specificare nome</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={groupClass('lastName')}>
                    <div className="job-form__text">
                      <input type="text" className="nj-form-control" name="lastName" id="jobFormLastName" minLength="5"
                        onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.lastName} />
                      <label htmlFor="jobFormLastName" className="nj-label-floating">Cognome</label>
                      {errorsMap.lastName ? (
                        <span className="job-form__warning">Specificare cognome</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className={groupClass('phoneNumber')}>
                    <div className="job-form__text">
                      <input type="text" className="nj-form-control" name="phoneNumber" id="jobFormPhone"
                        onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.phoneNumber} />
                      <label htmlFor="jobFormPhone" className="nj-label-floating">Telefono</label>
                      {/* <span className="nj-help">help</span> */}
                      {errorsMap.phoneNumber ? (
                        <span className="job-form__warning">Specificare telefono</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={groupClass('emailAddress')}>
                    <div className="job-form__text">
                      <input type="email" className="nj-form-control" name="emailAddress" id="jobFormEmail" autoComplete="email"
                        onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.emailAddress} />
                      <label htmlFor="jobFormEmail" className="nj-label-floating">Email</label>
                      {/* <span className="nj-help">We'll never share your email with anyone else.</span> */}
                      {errorsMap.emailAddress ? (
                        <span className="job-form__warning">Specificare email</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="nj-form-autocomplete pt-2">
                    <div className={groupClass('livesin')}>
                      <div className="job-form__text">
                        <input type="text" className="nj-form-control" name="livesin" id="jobFormProvince"
                          placeholder="Provincia di domicilio" aria-label="Provincia di domicilio" autoComplete="off"
                          onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.livesin} />
                        {/* <label htmlFor="jobFormProvince" className="nj-label-floating">Provincia di domicilio</label> */}
                        <div className="nj-form-autocomplete__list nj-card border"
                          style={{ zIndex: 'var(--nj-elevation-dropdown)' }}>
                          <div className="nj-list-group nj-list-group--no-border nj-list-group--sm">
                            {opzioniProvince.map(item => (
                              <button type="button" key={item.id} className="nj-list-group__item" data-value={item.id}
                                onClick={() => setProvincia(item.nome)}>
                                {item.nome}
                              </button>
                            ))}
                          </div>
                        </div>
                        {errorsMap.livesin ? (
                          <span className="job-form__warning">Specificare provincia scegliendo fra le opzioni</span>
                        ) : null}
                        <input type="hidden" value={formData.livesinId} name="livesinId" />
                        <input type="hidden" value={formData.region} name="region" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {typeForm?.value?.[0] === "manutentore" ?
                  <div className="col-12">
                    <NjSelect id="jobform-area-select" placeHolder="Area funzionale" name="functionalAreaCategoryId"
                      options={areeList.areaTecnica} option={areeList.areaTecnica[0]} setOption={setArea} disabled/>
                   </div>
                  : <>
                    <div className="col-md-6">
                      <NjSelect id="jobform-area-select" placeHolder="Area funzionale" name="functionalAreaCategoryId"
                        warning={errorsMap.functionalAreaCategoryId}
                        options={areeList.aree} option={formData.functionalAreaCategoryId} setOption={setArea} />
                      {errorsMap.functionalAreaCategoryId ? (
                        <span className="job-form__warning">Specificare area funzionale</span>
                      ) : null} </div>
                    <div className="col-md-6">
                      <NjSelect id="jobform-titolo-select" placeHolder="Titolo di studio" name="qualificationCategoryId"
                        warning={errorsMap.qualificationCategoryId}
                        options={titoliList.titoliStudio} option={formData.qualificationCategoryId} setOption={setTitolo} />
                      {errorsMap.qualificationCategoryId ? (
                        <span className="job-form__warning">Specificare titolo di studio</span>
                      ) : null}
                    </div>
                  </>}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={groupClass('messaggio')}>
                    <div className="job-form__textarea">
                      <textarea className="nj-form-control" id="areaMessage" name="messaggio" cols="30" rows="3"
                        onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.messaggio} >
                      </textarea>
                      <label htmlFor="areaMessage" className="nj-label-floating">Messaggio</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-6">
                  <div className="nj-form-group">
                    <div className="job-form__file d-flex">
                      <button className="nj-btn nj-btn--outline-primary">Scegli file</button>
                      <input type="file" ref={fileRef} className="job-form__file-input" name="cv" onChange={onChangeFile} aria-label="File" />
                      <span className="job-form__filename ml-2 t-kilo font-weight-light">
                        <span>{formData.cv ? formData.cv.name : 'Nessun file selezionato'}</span>
                        {formData.cv && <button className="job-form__file-cancel" onClick={cancelFile} aria-label="Cancella"></button>}
                      </span>
                    </div>
                  </div>
                  {errorsMap.cv ? (
                    <span className="job-form__warning">
                      Allegare un documento PDF o Word di dimensione massima 5Mb
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="nj-form-group">
                    <div className="nj-checkbox">
                      <label>
                        <input type="checkbox" name="privacyAgreement" checked={formData.privacyAgreement} onChange={onChange} />
                        Acconsento al trattamento dei dati personali per le finalità 
                        <span className="nj-checkbox__decorator">
                          <span className="nj-checkbox__check"></span>
                        </span>
                      </label>
                      <span> </span>
                      <ScrollLink to="job-form__privacy" smooth={true} onClick={showPrivacy} className="job-form__privacy-link">
                        indicate
                      </ScrollLink>
                    </div>
                    {errorsMap.privacyAgreement ? (
                      <span className="job-form__warning">
                        Acconsentire al trattamento dei dati
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="nj-form-group">
                    <button type="submit" className="nj-btn nj-btn--primary" disabled={loading}>
                      {loading ? (
                        <>
                          Loading...
                        <div className="nj-spinner nj-spinner--xs ml-1" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </>
                      ) : (
                          <span>Invia</span>
                        )}
                    </button>
                    {validationError ? (
                      <span className="ml-3 job-form__warning">{validationError}</span>
                    ) : null}
                    {remoteError ? (
                      <span className="ml-3 job-form__warning">{remoteError}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        <div>
          <span id="job-form__privacy" className="scroll-anchor"></span>
          <div className={`job-form__privacy${privacyVisible ? '' : ' d-none'}`}>
            <button className="job-form__privacy-close" onClick={closePrivacy}>
              <CloseIcon className="material-svg" />
            </button>
            {privacyText && privacyText.value ? (
              <div dangerouslySetInnerHTML={{__html: privacyText.value }}></div>
            ): null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobForm;
export const jobFormFragment = graphql`fragment Job_formFragment on LiferayJob_form{
  siteId
  articleId
  boxTitle {
    value
  }
  boxSubtitle {
    value
  }
  privacyText {
    value
  }
  typeForm {
    value
  }
}`;