import React from 'react';
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"
import Linkedin from '../../images/icons/linkedin.svg'


const goToLinkedin = (e,link) => {
  window.open(link, '_blank');
}

const TeamCard = ({ person, top = false }) => {
  const link = person.content && (
    (person.content.mainLinkedinLink && person.content.mainLinkedinLink.value) || 
    (person.content.linkedinLink && person.content.linkedinLink.value)
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => goToLinkedin(e,link) } className="team-card mb-4"> 
      <div href={link} target="_blank" rel="noopener noreferrer" className="team-card__container">
        <div className="position-relative overflow-hidden">
          {top && person.content && person.content.topPic && person.content.topPic.node ? (
            <>
              <Img className="team-card__img" image={getImage(person.content.topPic.node)}
              alt={person.content.topPic.node.description} />
              {person.content.topImgHd &&
                <a onClick={(e) => e.stopPropagation()} download={`${person.value}`} href={person.content.topImgHd.node.publicURL} title={person.value}>
                  <span className="team-card__download nj-icon nj-icon-document-download  "></span>
                </a>
              }
            </>
          ): null}
          {!top && person.content && person.content.picture && person.content.picture.node ? (
            <>
              <Img className="team-card__img" image={getImage(person.content.picture.node)}
                alt={person.content.picture.node.description} />
              {person.content.imgHd &&  
                <a onClick={(e) => e.stopPropagation()} download={`${person.value}`} href={person.content.imgHd.node.publicURL} title={person.value}>
                  <span className="team-card__download nj-icon nj-icon-document-download  "></span>
                </a>
              }
           </>  
          ): null}
          <span className="team-card__linkedin"><Linkedin/></span>
        </div>
        <div  className="team-card__text">
          <div className="team-card__name">{person.value}</div>
          {top && person.content && person.content.topRole && person.content.topRole.value ? (
            <div className="team-card__role">{person.content.topRole.value}</div>
          ): null}
          {!top && person.content && person.content.role && person.content.role.value ? (
            <div className="team-card__role">{person.content.role.value}</div>
          ): null}
        </div>
      </div>
    </div>
  );
}

export default TeamCard;