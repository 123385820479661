import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import CarouselModal from './carousel-modal'

const settings = {
  dots: true,
  infinite: false,
  autoplay: false,
  arrows: false,
  swipe: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '46px',
      }
    }
  ]
};

const Stories = ({ data: { mainTitle, cardStoria } }) => {
  const [showModal, setShowModal] = useState(false);
  const [videos, setVideos] = useState([]);
  console.log('cardStoria',cardStoria)
  
    

  const { pluginOptions: { adaptiveMediaPrefix } } = useStaticQuery(graphql`{
    pluginOptions: gatsbySourceLiferayOptions {
      adaptiveMediaPrefix
    }
  }`);

  const showSlider = i => {    
    setVideos(cardStoria[i].content.cardLinkVideo.map(video => ({ ...video, value: `${adaptiveMediaPrefix}${video.value}`})));
    setShowModal(true);
  }; 

  return (
    <div className="stories">
      <div className="container">
        <div className="row">
          <div className="col-12 pb-5 mb-5">
            <h3 className="stories__title">{mainTitle.value}</h3>
            {mainTitle.content && mainTitle.content.mainSubtitle ? (
              <div className="stories__subtitle mb-5" dangerouslySetInnerHTML={{ __html: mainTitle.content.mainSubtitle.value }}></div>
            ): null}
            {cardStoria ? (
              <>
                <Slider {...settings} className="stories__slider">
                  {cardStoria.map((item, i) => (
                    <div key={i}>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div className="stories__card d-flex flex-column justify-content-between" onClick={() => showSlider(i)}>
                        <Img image={getImage(item.node)} alt={item.node.description || ''} 
                          className="stories__img" />
                        <div className="stories__content position-relative">
                          <div className="stories__text" dangerouslySetInnerHTML={{ __html: item.content.cardDescrizione.value }}></div>
                          {item.content.engieName && item.content.engieName.value ? (
                            <div className="stories__category">
                              Engie
                              <span className="stories__paths">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </span>
                              {item.content.engieName.value}
                            </div>
                          ): null}
                        </div>
                        <button class={"stories__cta stories__cta-" + item.content.cardCssCta?.value?.[0] + " nj-btn nj-btn--lg position-relative nj-btn--primary mt-5"}>
                        {item.content.cardLabelCta?.value}                             
                        </button>                        
                      </div>
                    </div>
                  ))}
                </Slider>
                <CarouselModal show={showModal} handleClose={() => setShowModal(false)} videos={videos} />
              </>
            ): null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stories;
export const storiesFragment = graphql`fragment StoriesFragment on LiferayStories{
  siteId
  articleId
  mainTitle {
    value
    content {
      mainSubtitle {
        value
      }
    }
  }
  cardStoria {
    node {
      description
      gatsbyImageData(width: 465)
    }
    content {
      cardDescrizione {
        value
      }
      engieName {
        value
      }
      cardLabelCta {
        value
      }          
      cardCssCta {
        value
      }
      cardLinkVideo {
        value
        content { 
          cardLinkCta {
            value
          }          
        }
      } 
    }
  }
}`;