import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from './link'
import pageUrl from '../utility/page-url'


const CorrelatedArticle = ({ data: { mainTitle, relatedContent, articleTitle, 
    imageDesktop, imageMobile, articleLink, btnText }}) => {
  const { hero, intro, section1, heroImage, mainTitle: shortFormTitle } = relatedContent || {};
  const imgDesktop = imageDesktop // Immagine forzata
    || (hero && hero.imageDesktop) // Hero pagina interna
    || (intro && intro.imageDesktop) // Intro longform
    || (section1 && section1.content && section1.content.section1Image) // hero sezione 1 longform
    || heroImage; // Immagine hero shortform
  const imgMobile = imageMobile // Immagine forzata
    || (hero && hero.imageMobile) // Hero pagina interna
    || (intro && intro.imageMobile) // Intro longform
    || (heroImage && heroImage.content && heroImage.content.heroMobile); // Immagine hero shortform
  
  const imageTitle = (articleTitle && articleTitle.value) // titolo forzato
    || (hero && hero.imageTitle && hero.imageTitle.value) // titolo hero pagina interna
    || (intro && intro.mainTitle && intro.mainTitle.value) // titlo intro longform
    || (shortFormTitle && shortFormTitle.value); // titolo in pagina shortform

  const sources = useMemo(() => {
    return imgMobile ? withArtDirection(getImage(imgDesktop.node), [
      {
        media: "(max-width: 576px)",
        image: getImage(imgMobile.node),
      },
    ]): getImage(imgDesktop.node);
  }, [imgMobile, imgDesktop]);

  return (
    <div className="correlated-article">
      <div className="container">
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div className="col-12">
              <div className="correlated-article__title" dangerouslySetInnerHTML={{__html: mainTitle.value }}></div>
            </div>
          </div>
        ) : null }
        <div className="row">
          <div className="col-12">
            <Link to={articleLink.value ? articleLink.value : relatedContent && `/${pageUrl(relatedContent)}`} 
              className="correlated-article__link position-relative d-flex flex-column justify-content-end p-4 p-lg-5">
              <Img image={sources} alt={imgDesktop.node.description || ''} className="correlated-article__img" />
              <div className="correlated-article__content">
                <div className="correlated-article__articleTitle position-relative text-white" 
                  dangerouslySetInnerHTML={{__html: imageTitle }}></div>
                {btnText && btnText.value ? (
                  <button className="nj-btn nj-btn--light mt-5">{btnText.value}</button>
                ): null}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorrelatedArticle;
export const correlatedArticleFragment = graphql`fragment ArticoloCorrelatoFragment on LiferayArticolocorrelato{
  siteId
  articleId
  relatedContent {
    ... on LiferayPaginainterna {
      hero {
        ... on LiferaySiteHeader_Hero_Icone {
          imageDesktop {
            node {
              gatsbyImageData(width: 1108)
            }
          }
          imageMobile {
            node {
              gatsbyImageData(width: 540)
            }
          }
          imageTitle {
            value
          }
        }
      }
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
    }
    ... on LiferayLongForm {
      intro {
        ... on LiferayIntroLongFormLaterale {
          mainTitle {
            value
          }
        }
        ... on LiferayIntroLongForm {
          mainTitle {
            value
          }
          imageDesktop {
            node {
              gatsbyImageData(width: 1108)
            }
          }
          imageMobile {
            node {
              gatsbyImageData(width: 540)
            }
          }
        }
      }
      section1 {
        content {
          section1Image {
            node {
              gatsbyImageData(width: 1108)
            }
          }
        }
      }
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
    }
    ... on LiferayShortForm {
      heroImage {
        node {
          gatsbyImageData(width: 1108)
        }
        content {
          heroMobile {
            node {
              gatsbyImageData(width: 540)
            }
          }
        }
      }
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
      mainTitle {
        value
      }
    }
  }
  mainTitle {
    value
  }
  articleTitle {
    value
  }
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1108)
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 540)
    }
  }
  btnText {
    value
  }
  articleLink {
    value
  }
}`;
